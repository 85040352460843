.body{
    background-color: rgb(179, 139, 216);
    height: 600px;
    width: 100%;
    padding-top: 180px;
}

.diceContainer{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: aqua;
    font-size: xx-large;
    text-align: center;
    font-weight: 700;
    margin: auto;
    box-shadow: 0 0 10px 5px #888888;
    margin-top: -20px;
}

.diceContainer:hover{
    cursor: pointer;
    background-color: rgb(137, 241, 206);
}

.dice{
    display: block;
    margin: auto;
    padding-top: 12px;
}