.container{
    height: 322px;
    width: 480px;
    box-shadow:0 0 20px 5px #888888;
    border-radius: 10px;
    margin: auto;
    background-color: blueviolet;
}

.quote{
    color: white;
    font-size: 24px;
    position: relative;
    height: 50%;
    width:60%;
    margin: auto;
    padding-top: 20px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
}

.dice{
    height: 50px;
    width: 50px;
    border-radius: 100%;
    position: relative;
    margin-top: 50px;
    margin-left: 220px;
    background-color: aqua;
    font-size: xx-large;
    text-align: center;
    padding-top: 5px;
    font-weight: 700;
}

.dice:hover{
    cursor: pointer;
    background-color: rgb(28, 236, 166);
}

.title{
    display: block;
    text-align: center;
    color: aqua;
    letter-spacing:2px;
    padding-top: 50px;
}

.hr{
    width: 50%;
    margin: auto;
}